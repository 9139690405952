<template>
    <div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    </div>
</template>

<script>
    import env from "../configs/env.json";
    let host = '';
    if(location.hostname.includes('web')) {
      host = location.hostname.replace('web.', '')
      env.grafanaDomen = `https://${host}`
    }
    export default {
        data() {
            return {
                isLoading: true,
            }
        },
        mounted() {
            const params =  this.$route.query;
            const token = localStorage.getItem('token').slice(7);
            const urlDecode = encodeURI(`access_type=${params.access_type}&client_id=${params.client_id}&redirect_uri=${params.redirect_uri}&response_type=${params.response_type}&state=${params.state}&token=${token}`);
            const urlRedirect = `${env.grafanaDomen}/oauth/authorize?${urlDecode}`;
            window.location.href = urlRedirect;
        }
    }
</script>